.Navbar__section {
	background-color: white;
}

.Navbar__logo-item {
	padding: 0;
	padding-left: 0.75rem;
	img {
		max-height: 3rem;
	}
}
