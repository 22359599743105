.demo-page-header {
	background: #05141f;
	padding: 1em;

	> div {
		max-width: 1336px;
		margin: 0px auto;

		display: grid;
		grid-template-columns: 33% 33% 33%;
		grid-template-rows: auto;
		grid-template-areas: 'empty title button';

		@media (max-width: 768px) {
			grid-template-columns: 50% 50%;
			grid-template-rows: auto;
			grid-template-areas: 'title button';
		}

		h2 {
			grid-area: title;

			text-align: center;
			color: white;
			font-size: 1.5em;
		}

		.request-demo-button {
			grid-area: button;

			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			button {
				background: rgba(255, 255, 255, 0.2);
				border-radius: 0.3em;
				color: white;
				border: none;
				padding: 0.7em 1.4em;
				cursor: pointer;

				@media (max-width: 768px) {
					background: transparent;
				}

				span {
					@media (max-width: 768px) {
						display: none;
					}

					font-size: 1.05em;
					text-transform: uppercase;
					letter-spacing: 0.03em;
					margin-left: 0.5em;
				}
			}
		}
	}
}
