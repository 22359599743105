.setup-account-form {
	.errors {
		margin-bottom: 2em;
		color: #b01528;
		border: 1px solid #b01528;
		border-radius: 0.2em;
		padding: 1em;

		ul {
			margin-top: 1em;
			list-style: inherit;
			list-style-position: inside;
		}
	}
}
