
$kia-font: 'Kia Font', sans-serif;

$kia-black: #05141f;
$kia-dark-grey: #7e8083;
$kia-light-grey: #e4e5e6;
$kia-medium-grey: #c4c6c8;
$kia-text-grey: #58595b;

$primary: $kia-black;
$link: $kia-black;
$info: $kia-dark-grey;
$success: $kia-black;
$warning: $kia-medium-grey;
$dark: $kia-dark-grey;
$light: $kia-light-grey;

// $family-primary: $kia-font;
// $family-secondary: $kia-font;

$gap: 64px;

$widescreen: 934px + (2 * $gap);
$fullhd: 1126px + (2 * $gap);