.agreement-template {
	background-color: white;
	width: 100%;
}

.agreement-template-header {
	background: #05141f;
	padding: 1em;

	> div {
		max-width: 1336px;
		margin: 0px auto;

		display: grid;
		grid-template-columns: 33% 33% 33%;
		grid-template-rows: auto;
		grid-template-areas: 'empty title button';

		@media (max-width: 768px) {
			display: inline-block;
		}

		h2 {
			grid-area: title;

			text-align: center;
			color: white;
			font-size: 1.5em;

			.company-name {
				text-transform: uppercase;
				letter-spacing: 0.03em;
			}
		}

		.download-button {
			grid-area: button;

			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;

			@media (max-width: 768px) {
				margin-top: 0.5em;
				width: 100%;
			}

			button {
				background: rgba(255, 255, 255, 0.2);
				border-radius: 0.3em;
				color: white;
				border: none;
				padding: 0.7em 1.4em;
				cursor: pointer;

				i {
					margin-right: 0.5em;
				}

				@media (max-width: 768px) {
					width: fit-content;
					margin: 0px auto;
				}

				&:hover {
					background: rgba(255, 255, 255, 0.25);
				}
			}
		}
	}
}

.agreement-template-content {
	width: 100%;
	padding: 2em;

	@media (min-width: 1336px) {
		width: 1336px;
		margin: 0em auto 0px auto;
	}

	.not-agreed-warning {
		border: 1px solid #fb8a98;
		border-radius: 0.5em;
		padding: 1em;
		text-align: center;

		@media (max-width: 768px) {
			margin: 1em;
		}

		i {
			color: #b01528;
		}

		button {
			margin-top: 1em;
		}
	}

	.customer-info-table {
		margin: 1em;
		width: 400px;

		td,
		th {
			padding: 0.3em 0em;
		}
	}
}

.agreement-terms-and-conditions {
	padding: 1em;

	ol {
		padding-left: 1em;
		// list-style: inside;

		li {
			margin-bottom: 0.5em;
		}
	}
}
