.mobile-collapsible-section {
	.section-title {
		margin-left: 0.5em;
		margin-top: 1.5em;
		font-size: 1.2em;
		font-weight: bold;

		i {
			transition: 200ms ease-in-out;

			&.rotated {
				transform: rotate(-90deg);
			}
		}
	}

	.section-content {
		@media (max-width: 768px) {
			&.collapsed {
				display: none;
			}
		}
	}
}
