.fleet-profile-widget,
.agreement-list-widget {
	margin-top: 2em;
	margin-bottom: 0em;

	@media (max-width: 768px) {
		margin-top: 0em;
	}
}

.terms-have-changed-modal {
	background-color: white;
	border-radius: 0.5em;
	padding: 2em;
	text-align: center;

	p:first-child {
		margin-bottom: 1em;
	}
}
