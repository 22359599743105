.demo-table {
	width: 100%;

	@media (min-width: 1336px) {
		max-width: 1336px;
		margin: 1em auto 0px auto;
	}

	thead {
		tr {
			th {
				font-weight: normal;
				text-transform: uppercase;
				letter-spacing: 0.05em;
				color: #777;
				border-bottom: 2px solid #ddd;
				padding: 0 1rem 1rem;
			}
		}

		@media (max-width: 768px) {
			display: none;
		}
	}

	.mobile-info {
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		padding-left: 0.5em;

		@media (min-width: 769px) {
			display: none;
		}
	}

	img {
		width: 150px;

		@media (max-width: 768px) {
			margin-top: 0.5em;
			margin-bottom: 0.5em;
			width: 100px;
		}
	}

	tbody {
		tr {
			td {
				vertical-align: middle;
				padding: 0 1rem;
			}
		}

		tr:not(:last-child) {
			td {
				border-bottom: 1px solid #ddd;
			}
		}
	}

	.mobile-driver {
		font-weight: bold;
		color: #000;
	}

	.mobile-dates {
		color: #5c5c5c;
	}

	.demo-status {
		margin-left: 0.6em;
		font-size: 0.7em;
		padding: 0.3em 0.6em;
		border-radius: 4px;
		background-color: #eae4e4;
		color: black;

		&.green {
			background-color: #8eedc7;
			color: #0c6b58;
		}
	}

	.model-image-holder {
		@media (min-width: 768px) {
			min-height: 100px;
		}

		display: flex;
		align-items: center;
	}
}
