.agreements-table {
	width: 100%;

	@media (min-width: 769px) {
		max-width: 1336px;
		margin: 1em auto 0px auto;
	}

	.mobile-only {
		@media (min-width: 769px) {
			display: none;
		}
	}

	.desktop-only {
		@media (max-width: 768px) {
			display: none;
		}
	}

	thead {
		tr {
			th {
				font-weight: normal;
				text-transform: uppercase;
				letter-spacing: 0.05em;
				color: #777;
				border-bottom: 2px solid #ddd;
				padding-bottom: 1em;
			}
		}

		@media (max-width: 768px) {
			display: none;
		}
	}

	tbody {
		tr {
			&:hover {
				background-color: #eee;
				cursor: pointer;
			}

			td {
				vertical-align: middle;
				padding: 0.5em 0em;

				@media (max-width: 768px) {
					padding: 0.8em 0em;
				}
			}
		}

		tr:not(:last-child) {
			td {
				border-bottom: 1px solid #ddd;
			}
		}
	}

	.agreement-status {
		margin-left: 0.6em;
		font-size: 0.7em;
		padding: 0.3em 0.6em;
		border-radius: 4px;
		background-color: #ddd;
		color: #555;

		&.green {
			background-color: #8eedc7;
			color: #0c6b58;
		}
	}
}
