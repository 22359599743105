.DemoListWidget__requestButton {
	border: 1px solid $kia-black;
	color: $kia-black;
	text-transform: uppercase;
	font-size: 0.95em;
	letter-spacing: 0.03em;

	&:hover,
	&:focus {
		border: 1px solid $kia-black;
		color: $kia-black;
		background-color: rgba($color: ($kia-black), $alpha: 0.1);
		
	}
}