.reset-password-page {
	@media (min-width: 401px) {
		display: flex;
		height: calc(100vh - 62px);
		align-items: center;
	}

	> div {
		background-color: white;
		box-shadow: 2px 4px 8px #888888;
		padding: 2em;
		max-width: 400px;

		margin: 0px auto;
	}
}
