.ForgotPasswordPage__vertical-align {
	height: calc(100vh - 76px);
	display: flex;
	align-items: center;
}

.ForgotPasswordPage__container {
	margin: auto;
	width: 460px;
}

.ForgotPasswordPage__description-text {
	margin-bottom: 0.75rem;
}

.ForgotPasswordPage__back-link {
	display: block;
	margin-top: 0.75rem;
}