/*
Those classes modify bulma classes directly, use outdated varibles
or select native HTML elements and should be refactored
*/

$logo-font: 'Kia Font', sans-serif;
$primary-500: hsl(352, 79%, 45%); // Kia Red (Pantone 187C)
$primary-600: hsl(352, 79%, 35%);
$grey-50: hsl(352, 4%, 95%); // Kia Light Grey (Pantone 427C)
$grey-400: hsl(352, 2%, 55%);
$grey-600: hsl(352, 2%, 35%); // Kia Text Grey (Pantone 425C)

.navbar {
	.navbar-item {
		color: $grey-600;
		text-transform: uppercase;
		letter-spacing: 0.06em;
		font-size: 0.8em;
		font-family: $logo-font;

		@media (min-width: 1071px) {
			padding-left: 1em;
			padding-right: 1em;

			div {
				display: none;
			}

			&.active {
				background-color: $grey-50;
			}
		}

		@media (max-width: 1070px) {
			border-left: 2px solid transparent;

			div {
				float: left;
				width: 2em;
				margin-right: 1em;
				text-align: center;

				i {
					font-size: 1.2em;
					color: $grey-400;
				}
			}

			&.active {
				border-left: 2px solid $primary-500;
				background-color: $grey-50;

				div {
					i {
						color: $primary-600;
					}
				}
			}
		}
	}

.navbar-logo-text{
	font-family: $logo-font;
	font-weight:bold;
	text-transform: capitalize;
	font-size: large;
}
}

.pipe-border{
	border-right:2px solid $kia-medium-grey;
	height:25px;
	margin-left:0.5rem;
	margin-right:0.8rem;
}

.table {
	thead {
		th {
			font-size: 0.8em;
			font-family: $kia-font;
			text-transform: uppercase;
			letter-spacing: 0.07em;
			color: $kia-text-grey;
		}
	}

	th,
	td {
		vertical-align: middle;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $kia-font;
}

a.navbar-item {
	&:hover {
		color: $kia-black;
	}
}

.tabs.is-boxed {
	margin-bottom: 0;
}

.MarketingPreferenceForm {
	.explanation-text {
		margin-bottom: 1rem;
	}

	.field {
		margin-left: 1rem;
	}

	.button {
		margin-top: 1rem;
	}
}
