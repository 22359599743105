@media (min-width: 769px) {
	.login-page {
		height: calc(100vh - 52px);
		display: flex;
		justify-content: center;
		align-items: center;

		> div {
			width: 100%;
			max-width: 1000px;

			display: grid;
			display: -ms-grid;

			grid-template-rows: 100%;
			grid-template-columns: auto 400px;
			grid-template-areas: 'login_image login_form';

			-ms-grid-rows: 100%;
			-ms-grid-columns: 60% 40%;

			background-color: white;
			box-shadow: 2px 4px 8px #888888;

			h2 {
				font-size: 1.5em;
				text-align: center;
				margin-bottom: 1em;
			}

			.login-image {
				-ms-grid-row: 1;
				-ms-grid-column: 1;

				grid-area: login_image;

				background-image: url('/login-image.jpg');
				background-size: cover;
				background-position: center;
			}

			.login-form-holder {
				-ms-grid-row: 1;
				-ms-grid-column: 2;

				grid-area: login_form;

				display: flex;
				justify-content: center;
				align-items: center;

				> div {
					width: 100%;
					padding: 2em;

					.forgot-link {
						margin-top: 1em;
					}
				}
			}
		}
	}
}

.login-form {
	.error {
		color: #bb162b;
		border-radius: 0.3em;
		padding: 0.2em 0.4em;
		margin-bottom: 1em;
	}
}

@media (max-width: 768px) {
	.login-page {
		> div {
			width: 100%;

			h2 {
				font-size: 1.5em;
				text-align: center;
				margin-bottom: 1em;
			}

			.login-image {
				height: 40vw;
				background-image: url('/login-image.jpg');
				background-size: cover;
				background-position: center;
			}

			.login-form-holder {
				width: 100%;
				padding: 2em;

				> div {
					width: 100%;

					.forgot-link {
						margin-top: 1em;
					}
				}
			}
		}
	}
}
