.MustChangePasswordPage__vertical-align {
	height: calc(100vh - 76px);
	display: flex;
	align-items: center;
}

.MustChangePasswordPage__container {
	margin: auto;
	width: 460px;
}

.MustChangePasswordpage__change-information {
	margin-bottom: 0.5rem;
}