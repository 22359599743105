.agreement-list-page-header {
	background: #05141f;
	padding: 1em;
	color: white;

	h2 {
		text-align: center;
		color: white;
		font-size: 1.5em;
	}
}
