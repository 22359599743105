.RequestDemoPage__vehicle {
	padding: 1rem;

	&:hover {
		background: #05141f;
		color: white;
		cursor: pointer;
	}
}

.RequestDemoPage__vehicle__holder {
	display: flex;
	align-items: center;

	min-height: 137px;
}

.RequestDemoPage__vehicle--active {
	background: #05141f;
	color: white;
}

.RequestDemoPage__vehicle__description {
	margin-top: 0.5rem;
}

.RequestDemoPage__vehicle__description__icon {
	width: 1.5rem;
}

.RequestDemoPage__vehicle__try-button {
	background-color: rgba(#fff, 0.15);
	text-transform: uppercase;
	color: #fff;
	margin-top: 1rem;
	border: none;

	i {
		font-size: 0.7rem;
		margin-left: 1rem;
	}

	&:hover {
		background-color: rgba(#fff, 0.2);
		color: #fff;
	}
}

.RequestDemoPage__container {
	padding: 0;
}

.RequestDemoPage__header {
	border-radius: 6px 6px 0 0;
	display: flex;
	justify-content: center;
	padding: 1rem 0;
}

.RequestDemoPage__header__inner {
	display: flex;
	align-items: center;
	@media (max-width: 768px) {
		flex-direction: column;

		h2 {
			text-align: center;
		}
	}
}

.RequestDemoPage__header__image {
	max-width: 250px;
	margin-right: 1rem;
}

.RequestDemoPage__content {
	padding: 1.25rem;
}

.RequestDemopage__info-text {
	margin-bottom: 1rem;
}

.RequestDemoPage__transmission {
	display: flex;
	justify-content: center;
	margin: 1rem auto 2rem auto;
	max-width: 540px;
}

.RequestDemoPage__transmission__button {
	font-size: 1.2em;
	font-weight: bold;
	text-transform: uppercase;
	border: 2px solid #888;

	&:hover {
		box-shadow: #888 0px 0px 4px;
	}

	i {
		font-size: 1.2em;
		margin-right: 0.5em;
	}

	&.active {
		border: 2px solid $kia-black;
		color: $kia-black;

		&:hover {
			box-shadow: $kia-black 0px 0px 4px;
			// color: $kia-red;
			// border: 2px solid $kia-red;
		}

		i {
			color: $kia-black;
		}
	}
}

.RequestDemopage__transmission__spacer {
	width: 1rem;
}

.request-demo-page-header {
	background: #05141f;
	padding: 1em;

	h2 {
		text-align: center;
		color: white;
		font-size: 1.5em;
	}
}
