@font-face {
	font-family: 'Kia Font';
	font-weight: lighter;
	font-style: normal;
	src: url('./font/kia-light.eot');
	src: url('./font/kia-light.eot?#iefix') format('embedded-opentype'), url('./font/kia-light.woff') format('woff'),
		url('./font/kia-light.ttf') format('ttf'), url('./font/kia-light.svg') format('svg');
}
@font-face {
	font-family: 'Kia Font';
	font-weight: normal;
	font-style: normal;
	src: url('./font/kia-medium.eot');
	src: url('./font/kia-medium.eot?#iefix') format('embedded-opentype'), url('./font/kia-medium.woff') format('woff'),
		url('./font/kia-medium.ttf') format('ttf'), url('./font/kia-medium.svg') format('svg');
}
@font-face {
	font-family: 'Kia Font';
	font-weight: bold;
	font-style: normal;
	src: url('./font/kia-bold.eot');
	src: url('./font/kia-bold.eot?#iefix') format('embedded-opentype'), url('./font/kia-bold.woff') format('woff'),
		url('./font/kia-bold.ttf') format('ttf'), url('./font/kia-bold.svg') format('svg');
}
@import url('https://fonts.googleapis.com/css?family=Roboto');
