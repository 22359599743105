.table-container {
    overflow-x: auto;
}

.loader {
    position: relative;
    pointer-events: none;
    opacity: 1;
    @include loader;
    position: absolute;
    top: calc(50%);
    left: calc(50% - 2.5rem);
    width: 5em;
    height: 5em;
    border-width: 0.25em;
}