@import 'fonts';
@import 'variables';
@import 'bulma';

@import 'helpers';

@import 'components/DemoTable.scss';
@import 'components/DemoListPage.scss';
@import 'components/DemoListWidget.scss';
@import 'components/ForgotPasswordPage.scss';
@import 'components/ResetPasswordPage.scss';
@import 'components/LoginPage.scss';
@import 'components/MustChangePasswordPage.scss';
@import 'components/Navbar.scss';
@import 'components/RequestDemoPage.scss';
@import 'components/SettingPage.scss';
@import 'components/AgreementListPage.scss';
@import 'components/AgreementTable.scss';
@import 'components/KiaTemplate.scss';
@import 'components/MobileCollapsibleSection.scss';
@import 'components/DashboardPage.scss';

// Kia font breaks the dot character, so we need to

// set a standard font for input dot characters

input[type='password'] {
	font-family: sans-serif;
}

#root {
	background-color: #f2f2f3;
	min-height: 100vh;
}

@import 'to-refactor';

.desktop-only {
	@media (max-width: 768px) {
		display: none;
	}
}

.mobile-only {
	@media (min-width: 769px) {
		display: none;
	}
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 100;

	display: flex;
	justify-content: center;
	align-items: center;
}

.custom-fc-widget{
	background-color: $kia-black  !important;
}