.rotateForever {
	animation: rotation 1s infinite linear;
	-webkit-animation: rotation 1s infinite linear;
}

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}

.setup-account-page {
	@media (min-width: 769px) {
		height: calc(100vh - 52px);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	> div {
		width: 100%;
		@media (min-width: 401px) {
			max-width: 400px;
		}

		padding: 1em;

		background-color: white;
		box-shadow: 2px 4px 8px #888888;
	}
}
